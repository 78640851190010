@import "~antd/dist/antd.css";

.logo {
  height: 30px;
  margin: 16px 24px 16px 0;
  cursor: default;
}

.statusSystem {
  float: right;
  margin-top: 21px;
  margin-bottom: 21px;
}

.statusSystem:hover {
  opacity: 1;
}
